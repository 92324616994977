.mutability-toggle
{    
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    position: relative;
}


.mutability-toggle > *
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.mutability-toggle:disabled > *
{    
    opacity: 0.3;
}
.editable-block
{
    display: flex;
    min-height: 2.5em;
    padding: var(--big-gap-length);
    background: #ffffff;
    border: 1px solid #000000;
}


.editable-block > :first-child
{
    flex-grow: 1;
}


.editable-block .removal-button,
.editable-block .mutability-toggle
{
    width: 1.25em;
    flex-shrink: 0;
    outline: none;
}


.editable-block .removal-button
{
    position: relative;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
}


.editable-block .removal-button+.mutability-toggle
{
    margin-left: 0.1em;
}


.editable-block > .removal-button img
{
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
}


.editable-block > .removal-button:disabled img
{    
    opacity: 0.3;
}
.product-detail-preview > *+*
{
    margin-top: var(--big-gap-length);
}


.product-detail-preview .product-visuals
{
    display: flex;
}


.product-detail-preview .product-visuals > *
{
    flex-grow: 1;
}


.product-detail-preview .product-visuals > *+*
{
    margin-left: var(--big-gap-length);
}


.product-detail-preview .product-visuals > .variable-list
{
    flex-grow: 0;
}


.product-detail-preview .product-perspectives
{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: min-content;
    gap: var(--big-gap-length);
    align-items: center;
}


.product-detail-preview .name,
.product-detail-preview .configuration-definition
{
    display: block;
}


.product-detail-preview .name
{
    margin-left: 8px;
}


.product-detail-preview .product-perspectives img
{
    max-width: 100%;
    max-height: 100%;
}


.product-detail-preview .missing-motice-container
{
    font-size: var(--small-font-size);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.product-detail-preview .copy-to-clipboard-button
{
    padding: 2px 3px;
}


.product-detail-preview .buttons-row
{
    display: flex;
    align-items: flex-start;
}


.product-detail-preview .buttons-row > *+*
{
    margin-left: var(--small-gap-length);
}
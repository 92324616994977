.app
{
    --big-gap-length: 0.5rem;
    --small-gap-length: 0.25rem;
    --small-font-size: 0.75rem;
    --disabled-color: #e8e8e8;
    
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}


*
{
    box-sizing: border-box;
    font-family: inherit;
    font-size: inherit;
}


button
{
    cursor: pointer;
}


fieldset
{
    min-width: 0;
}


h1,
h2
{
    margin: 0;
}


input
{
    min-width: 0;
}


ul
{
    margin: 0;
    padding: 0;
    list-style: none;
}


.app > .loading-indicator
{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.navigation-button
{
    width: 2.5vw;
    height: 2.5vw;
    background: #2a95a320;
    border: none;
    border-radius: 50%;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}


.navigation-button:disabled
{
    background: #00000020;
}


.navigation-button:not(:disabled):hover
{
    font-weight: bold;
    background: #2a95a340;
}
.four-oh-four-page
{
    display: flex;
    justify-content: center;
    align-items: center;
}


.four-oh-four-page > div
{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4.04rem;
    text-align: center;
}


.four-oh-four-page > div > *+*
{
    margin-left: 1rem;
}


.four-oh-four-page a
{
    color: inherit;
    font-size: 1rem;
    display: block;
}
.project-view > *:not(.navigation-button-container, h1)
{
    box-shadow: inset 0 0 5px;
    padding: var(--big-gap-length);
}


.project-view .navigation-button-container
{
    display: flex;
    justify-content: center;
    align-items: center;
}


.project-view .top-block
{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: var(--big-gap-length);
}


.project-view .top-block > *
{
    flex-grow: 1;
}


.project-view .top-block input
{
    width: 100%;
}


.project-view h1
{
    font-size: 1.5rem;
    text-align: center;
}


.project-view .style-comparison-preview
{
    height: 50vh;
}


.project-view .clear-cache-block
{
    box-shadow: none;
}
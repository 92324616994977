.slots-list
{    
    margin: 0;
    padding: 0;
    border: none;
}


.slots-list .slot+.slot
{
    margin-top: 0.5rem;
}


.slots-list legend
{
    padding: 0;
    margin-bottom: 0.5em;
    font-weight: bold;
}


.slots-list label
{
    display: block;
    font-size: var(--small-font-size);
}


.slots-list select
{
    width: 100%;
}
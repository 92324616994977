.text-image-slots-list
{    
    margin: 0;
    padding: 0;
    border: none;
}


.text-image-slots-list .slot+.slot
{
    margin-top: 0.5rem;
}


.text-image-slots-list legend
{
    padding: 0;
    margin-bottom: 0.5em;
    font-weight: bold;
}



.text-image-slots-list label
{
    display: block;
    font-size: var(--small-font-size);
}


.text-image-slots-list .text-image-dropdown
{
    width: 100%;
}
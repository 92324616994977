.product-configuration-preview-top-block
{    
    padding: 0 var(--big-gap-length);
    display: flex;
    flex-direction: column;
}


.product-configuration-preview-top-block > *+*
{
    margin-top: var(--small-gap-length);
}


.product-configuration-preview-top-block .top-buttons
{
    display: flex;
    font-size: var(--small-font-size);
}


.product-configuration-preview-top-block .top-buttons > *
{
    flex-grow: 1;
    flex-basis: 0;
    flex-shrink: 1;
}


.product-configuration-preview-top-block .top-buttons .copy-to-clipboard-button
{
    border-radius: 5px 0 0 5px;
    border: 1px solid;
}


.product-configuration-preview-top-block .top-buttons .button-link
{
    border-radius: 0 5px 5px 0;
    border: 1px solid;
    border-left: none;
}


.product-configuration-preview-top-block .product-main-image
{
    display: block;
    max-width: 100%;
    cursor: pointer;
}


.product-configuration-preview-top-block .product-name
{
    display: block;
    width: 100%;
    text-align: center;
    cursor: pointer;
}


.product-configuration-preview-top-block .notice-text
{
    display: block;
    font-size: var(--small-font-size);
    text-align: center;
}


.product-configuration-preview-top-block .colors-list
{
    flex-grow: 1;
}
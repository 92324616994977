li.colors-list-item
{
    text-align: center;
    padding: var(--small-gap-length);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2em;
}


.colors-list-item.flash-highlighted
{
    animation: inversion-flash 1s;
}


@keyframes inversion-flash
{
    0% { filter: invert(1); };
    100% { filter: invert(0); };
}


.colors-list-item.bordered
{
    border: 1px solid #000000;
}


.colors-list-item img
{
    max-width: 1em;
    max-height: 1em;
    margin-right: var(--small-gap-length);
}


.colors-list-item img.inversion-filtered
{
    filter: invert(1);
}
.home-page
{
    display: flex;
    justify-content: center;
    align-items: center;
}


.home-page .start-menu
{
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
}
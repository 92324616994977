.page.projects-view .variable-list
{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}


.page.projects-view .clear-cache-block
{
    text-align: center;
}
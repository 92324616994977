.text-image-dropdown
{
    --option-height: calc(1.5625em * 2);
    --displaying-items-count: 6;

    display: inline-block;
    height: var(--option-height);
    position: relative;
    user-select: none;
}


.text-image-dropdown.disabled
{
    pointer-events: none;
}


.text-image-dropdown.disabled .header
{
    background: var(--disabled-color);
}


.text-image-dropdown .options-container
{
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: none;
    max-height: calc(var(--displaying-items-count) * var(--option-height));
    overflow-y: auto;
    border: 1px solid rgb(118, 118, 118);
}


.text-image-dropdown.dropped-down .options-container
{
    display: block;
    z-index: 1;
}


.text-image-dropdown .header,
.text-image-dropdown .option
{
    display: flex;
    height: var(--option-height);
    padding: calc(var(--option-height) / 8);
    box-sizing: border-box;
    background: #ffffff;
    align-items: center;
    white-space: pre;
}


.text-image-dropdown .header
{
    display: flex;
    padding-right: calc(var(--option-height) / 8 + 22px);
    border: 1px solid rgb(118, 118, 118);
}


.text-image-dropdown .option > *+*
{
    margin-left: var(--big-gap-length);
}


.text-image-dropdown .option:hover
{
    background: #1e90ff;
}


.text-image-dropdown .option-label
{
    flex-grow: 1;
    overflow-x: clip;
    text-overflow: ellipsis;
}


.text-image-dropdown .option-image-container
{
    flex-shrink: 0;
    height: 100%;
    width: var(--option-height);
    display: flex;
    justify-content: center;
    align-items: center;
}


.text-image-dropdown .option-image
{
    max-width: 100%;
    max-height: 100%;
}


.text-image-dropdown .drop-arrow
{
    position: absolute;
    right: calc(var(--option-height) / 8);
    top: 50%;
    transform: translateY(-50%);
}
.color-text-input
{
    position: relative;
    border: 1px solid #000000;
}


.color-text-input.empty-selection
{
    border-style: dotted;
}


.color-text-input.dark-colored .eyedropper-icon
{
    filter: invert(1);
}


.color-text-input .hex-color-input
{
    height: 100%;
    width: 100%;
    padding-left: 2em; /* to center despite eyedropper icon */
    text-align: center;
    flex-grow: 1;
    border: none;
}


.color-text-input.empty-selection .hex-color-input
{
    background: transparent;
}


.color-text-input.dark-colored .hex-color-input
{
    color: #ffffff;
}


.color-text-input .color-picker-button
{
    position: absolute;
    bottom: 0.5em;
    left: 0.5em;
    height: 1em;
    width: 1em;
    border: none;
    margin: 0;
    padding: 0;
    display: block;
    background: none;
}


.color-text-input .hidden-color-picker-input
{
    position: absolute;
    right: -5px;
    bottom: 2em;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: none;
    visibility: hidden;
    pointer-events: none;
}


.color-text-input .eyedropper-icon
{
    display: block;
}
.button-link
{
    border: 1px solid #808080;
    padding: 2px 4px;
    color: #000000;
    text-decoration: none;
    background: #ffffff;
    border-radius: 3px;

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}


.button-link:not(.disabled):hover
{
    background: #e0e0e0;
}


.button-link.disabled
{
    opacity: 0.8;
    cursor: default;
}
.gallery img
{    
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 0 calc(var(--big-gap-length) / 2) #000000;
}


.gallery .masonry-grid
{
    display: flex;
    margin-left: calc(-1 * var(--big-gap-length)); /* gutter size offset */
    width: auto;
}


.gallery .masonry-grid-column
{
    padding-left: var(--big-gap-length); /* gutter size */
    background-clip: padding-box;
}


.gallery .masonry-grid-column > *
{
    display: block;
}


.gallery .masonry-grid-column > *+*
{
    margin-top: var(--big-gap-length);
}
.login-view
{
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);
}


.login-view > fieldset > *
{
    display: block;
}


.login-view > fieldset > * + *
{
    display: block;
    margin-top: 0.5em;
}


.login-view input[type='submit']
{
    margin-left: auto;
    margin-right: auto;
}


.login-view label > *+*
{
    margin-left: 0.5em;
}
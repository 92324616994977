.style-comparison-preview
{
    display: flex;
    align-items: center;
}


.style-comparison-preview > *+*
{
    margin-left: var(--big-gap-length);
}


.style-comparison-preview .image-container
{
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    align-items: center;
    height: 100%;
}


.style-comparison-preview .left-image-container
{
    justify-content: flex-end;
}


.style-comparison-preview .right-image-container
{
    justify-content: flex-start;
}


.style-comparison-preview
{
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    align-items: center;
    height: 100%;
}


.style-comparison-preview img
{
    max-width: 100%;
    max-height: 100%;
}


.style-comparison-preview .notice-text
{
    display: block;
    font-size: var(--small-font-size);
    text-align: center;
}
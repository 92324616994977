.page
{
    width: 100%;
    max-width: 1440px;
    min-height: 100vh;
    margin: 0 auto;
    background: #dadada;
    padding: var(--big-gap-length);
}


.page > *+*
{
    margin-top: var(--big-gap-length);
}
.projects-list-item-label
{
    border: none;
    outline: none;
    background: none;
    padding: 0;
    overflow-x: clip;
    height: 100%;
    width: 100%;
}


.projects-list-item-label .inner-section
{
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}


.projects-list-item-label .inner-section+.inner-section
{
    margin-top: var(--small-gap-length);
}


.projects-list-item-label .images
{
    height: 3.75em;
}


.projects-list-item-label .images img
{
    height: 100%;
    object-fit: contain;
}


.projects-list-item-label .images img+img
{
    margin-left: var(--small-gap-length);
}


.projects-list-item-label .text-container span
{
    flex-grow: 1;
}


.projects-list-item-label .text-container span
{
    overflow-x: hidden;
    text-overflow: ellipsis;
}


.projects-list-item-label .image-container
{
    height: 100%;
}
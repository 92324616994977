.search-bar input
{
    width: 100%;
    padding: var(--big-gap-length);
    border-radius: 3px;
    border: 1px solid #000000;
}


.search-bar input[disabled]
{
    background: #dedede;
}
.product-configuration-definitions-list-item-label
{
    flex-grow: 1;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    background: none;
    text-align: left;
    padding: 0;
    overflow-x: clip;
}


.product-configuration-definitions-list-item-label:disabled
{
    cursor: default;
}


.product-configuration-definitions-list-item-label .label-text
{
    flex-grow: 1;
    white-space: nowrap;
    overflow-x: clip;
    text-overflow: ellipsis;
}


.product-configuration-definitions-list-item-label .label-image-container
{
    width: 3em;
    height: 3em;
    flex-shrink: 0;
    margin: var(--small-gap-length);
    display: flex;
    justify-content: center;
    align-items: center;
}


.product-configuration-definitions-list-item-label .label-image-container img
{
    max-width: 100%;
    max-height: 100%;
}
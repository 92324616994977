.collapsible-block > .header
{
    height: 1.5em;
    display: flex;
    align-items: center;
    font-weight: bold;
}


.collapsible-block > .header button
{
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}


.collapsible-block > .header .title
{
    margin-left: var(--small-gap-length);
    display: none;
    flex-grow: 1;
}


.collapsible-block.collapsed > .header .arrow
{
    max-width: 100%;
    max-height: 100%;
    transform: rotate(-90deg);
}


.collapsible-block.collapsed > .header .title
{
    margin-left: var(--small-gap-length);
    display: unset;
}


.collapsible-block.collapsed > .children-container
{
    display: none;
}
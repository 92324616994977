.product-configurations-preview
{
    padding-bottom: var(--big-gap-length);
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: hidden;
    --min-product-configuration-preview-column-width: 210px;
}


.product-configurations-preview > *+*
{
    margin-top: var(--big-gap-length);
}


.product-configurations-preview .product-configuration-preview-top-block,
.product-configurations-preview .colors-list,
.product-configurations-preview .color-mappings-list
{
    min-width: var(--min-product-configuration-preview-column-width);
    flex-grow: 1;
}


.product-configurations-preview .preview-columns-row
{
    display: flex;
}
.editable-linear-list
{
    margin: 0;
    padding: 0;
    border: none;
}


.editable-linear-list ul
{
    padding: 0;
    list-style: none;
}


.editable-linear-list .stored-items-list > li
{    
    box-shadow: 0 0 3px #00000080;
}


.editable-linear-list .stored-items-list > li+li
{
    margin-top: var(--big-gap-length);
}


.editable-linear-list .adding-list-entry
{
    display: flex;
    align-items: center;
    border: 1px solid #000000;
    padding: var(--big-gap-length);
    background: #ffffff;
    opacity: 0.5;
}


.editable-linear-list:not(.disabled) .adding-list-entry:hover,
.editable-linear-list:not(.disabled) .adding-list-entry:focus-within
{
    opacity: 1;
}


.editable-linear-list .adding-list-entry > *+*
{
    margin-left: var(--small-gap-length);
}


.editable-linear-list .adding-list-entry .options-input
{
    flex-grow: 1;
}


.editable-linear-list .adding-list-entry .add-button
{
    width: 1.25em;
    height: 1.25em;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    outline: none;
}


.editable-linear-list .adding-list-entry .add-button img
{
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;
    background: #ffffff;
    border-radius: 50%;
}


.editable-linear-list legend
{
    padding: 0;
    margin-bottom: 0.5em;
    font-weight: bold;
}
.color-text-input-group > *
{
    width: 100%;
}


.color-text-input-group label
{
    font-size: var(--small-font-size);
}


.color-text-input-group .color-text-input
{
    height: 2em;
}
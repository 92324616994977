.copy-to-clipboard-button
{
    padding: 0;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
}


.copy-to-clipboard-button:hover,
.copy-to-clipboard-button:focus
{
    background: #e0e0e0;
}
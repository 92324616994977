.editable-card
{
    display: flex;
    min-height: 2.5em;
    padding: var(--big-gap-length);
    background: #ffffff;
    border: 1px solid #000000;
}


.editable-card > *+*
{
    margin-left: var(--big-gap-length);
}


.editable-card > :first-child,
.editable-card > :last-child
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 1.25em;
    flex-shrink: 0;
}


.editable-card > :nth-child(2)
{
    flex-grow: 1;
    overflow-x: clip;
    display: flex;
    justify-content: center;
    align-items: center;
}


.editable-card .removal-button,
.editable-card .mutability-toggle
{
    outline: none;
}


.editable-card .removal-button+.mutability-toggle
{
    margin-left: 0.1em;
}


.editable-card > :first-child > *,
.editable-card > :last-child > *
{    
    width: 100%;
    height: 1.25em;
    position: relative;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
}


.editable-card > .removal-button:disabled img
{    
    opacity: 0.3;
}


.editable-card input
{
    text-align: center;
}
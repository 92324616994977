.hierarchical-colors-input-group
{
    position: relative;
}


.hierarchical-colors-input-group > *
{
    width: 100%;
}


.hierarchical-colors-input-group .color-input-row
{
    height: 2em;
}


.hierarchical-colors-input-group .color-input-row > *
{
    height: 100%;
}


.hierarchical-colors-input-group .color-input-row+.color-input-row > *
{
    border-top: none;
}


.hierarchical-colors-input-group label
{
    font-size: var(--small-font-size);
}